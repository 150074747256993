import { create, get, on } from 'dtk';
import { queue } from '../../common/util';
import { clearError, formError } from '../lib/util';
import { grantDealerAccess, revokeDealerAccess, setDealerActive, } from '../lib/api';
import { showBubbleAt } from '../blocks/bubble';
import { initAppHeader } from '../blocks/header';
export const initDealerPage = (c, root, data) => {
    initAppHeader();
    const dealer = data.dealer;
    initAddDealerAccessForm(get(root, 'form', '.app-dealer__add-access'), dealer);
    const at = get(root, 'table', '.app-dealer__access-table');
    at && on(at, 'click', '.app-dealer__access-revoke', async (e) => {
        const access = e.target.closest('tr').dataset.id;
        const res = await revokeDealerAccess({ access });
        if (res.error)
            showBubbleAt(e.target, create('div', 'bubble bubble--error', res.error));
        if (res.success)
            location.reload();
    });
    const abtn = get(root, '.app-dealer__set-active');
    abtn && on(abtn, 'click', async (e) => {
        const active = !!parseInt(e.target.dataset.value);
        const res = await setDealerActive({ dealer: dealer.id, active });
        if (res.error)
            showBubbleAt(e.target, create('div', 'bubble bubble--error', res.error));
        if (res.success)
            location.reload();
    });
};
function initAddDealerAccessForm(form, dealer) {
    let emailInput = get(form, 'input', '[name=email]');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = emailInput.value.trim();
        if (!email)
            return formError(form, 'Please fill in the email address');
        const res = await grantDealerAccess({
            dealer: dealer.id,
            email,
        });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success) {
            location.reload();
            return queue.done;
        }
    }), { prevent: true });
}
