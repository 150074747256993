import { create, on } from 'dtk';
export function initViewer(root, { cookie, terminal }) {
    let width = 1024;
    let height = 768;
    const canvas = create('canvas', { width: 1024, height: 768 });
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#999';
    ctx.fillRect(0, 0, 1024, 768);
    const screen = create('div', 'viewer-screen', canvas);
    root.appendChild(screen);
    let ws;
    let buffer;
    function send(msg) {
        ws.send(JSON.stringify(msg));
    }
    function handle(raw) {
        if (typeof raw != 'string') {
            buffer = raw;
            return;
        }
        const msg = JSON.parse(raw);
        if (msg.paint) {
            if (msg.paint.x + msg.paint.w > width || msg.paint.y + msg.paint.h > height) {
                canvas.width = width = Math.max(width, msg.paint.x + msg.paint.w);
                canvas.height = height = Math.max(height, msg.paint.y + msg.paint.h);
            }
            const burl = URL.createObjectURL(buffer);
            const img = create('img');
            img.onload = () => {
                ctx.drawImage(img, msg.paint.x, msg.paint.y);
                URL.revokeObjectURL(burl);
            };
            img.onerror = e => {
                console.error(e);
                URL.revokeObjectURL(burl);
            };
            img.src = burl;
        }
    }
    let connecting = false;
    let attempt = 0;
    function connect() {
        connecting = true;
        const proto = location.protocol == 'https:' ? 'wss:' : 'ws:';
        ws = new WebSocket(`${proto}//${location.host}/viewer/socket`);
        ws.onopen = () => {
            attempt = 0;
            send({ init: { cookie, terminal } });
        };
        ws.onclose = ev => {
            attempt++;
            setTimeout(connect, Math.min(10, attempt) * 500);
        };
        ws.onmessage = ev => handle(ev.data);
    }
    connect();
    on(canvas, 'click', e => {
        const br = canvas.getBoundingClientRect();
        send({ click: {
                x: Math.floor(e.offsetX * (width / br.width)),
                y: Math.floor(e.offsetY * (height / br.height)),
            } });
        const mark = create('div', 'viewer-screen__click');
        mark.style.top = (e.offsetY - 50) + 'px';
        mark.style.left = (e.offsetX - 50) + 'px';
        screen.appendChild(mark);
        requestAnimationFrame(e => {
            mark.getBoundingClientRect();
            mark.classList.add('viewer-screen__click--fade');
        });
        setTimeout(() => {
            screen.removeChild(mark);
        }, 2000);
    });
}
;
