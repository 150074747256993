import { parse as parsePath } from 'path';
import * as dayjs from 'dayjs';
import * as cookie from 'cookie';
function flattenTo(arr, to) {
    for (let i = 0, l = arr.length; i < l; i++) {
        if (Array.isArray(arr[i]))
            flattenTo(arr[i], to);
        else
            to.push(arr[i]);
    }
}
export function flatten(arr) {
    if (!Array.isArray(arr))
        return [arr];
    const out = [];
    flattenTo(arr, out);
    return out;
}
export function shuffle(a) {
    for (let i = a.length; i;) {
        const j = Math.floor(Math.random() * i--);
        [a[i], a[j]] = [a[j], a[i]];
    }
}
export function camelToKebab(s) {
    let fs = (s[0].match(/[A-Z]/) || s.match(/^ms/)) ? '-' + s[0].toLowerCase() + s.substr(1) : s;
    return fs.replace(/[A-Z]/g, c => '-' + c.toLowerCase());
}
export function matchRoute(parts, match) {
    const ridx = match.indexOf('**');
    const mlen = ridx >= 0 ? ridx : match.length;
    if (mlen > parts.length)
        return false;
    const args = [];
    let i = 0;
    for (; i < parts.length; i++) {
        if (match.length <= i)
            return false;
        const mpart = match[i];
        if (mpart == '**') {
            args.push(parts.slice(i).join('/'));
            return args;
        }
        else if (mpart == '*') {
            args.push(parts[i]);
        }
        else if (mpart != parts[i]) {
            return false;
        }
    }
    if (i == parts.length)
        return args;
    return false;
}
export function setCookie(key, value, age) {
    const params = { path: '/' };
    params.maxAge = typeof age == 'undefined' ? 86400 * 365 * 5 : age;
    document.cookie = cookie.serialize(key, value, params);
}
export function removeUndefined(obj) {
    return Object.keys(obj).filter(e => obj[e] !== undefined).reduce((o, e) => { o[e] = obj[e]; return o; }, {});
}
export function filterDate(date, from, till) {
    return !dayjs(date).isBefore(from) && dayjs(date).isBefore(till.add(1, 'day'));
}
export function formToJSON(form) {
    const out = {};
    for (let input of Array.from(form.querySelectorAll('input, select, textarea'))) {
        const name = input.name;
        if (!name)
            continue;
        let val = void 0;
        if (input instanceof HTMLSelectElement) {
            const so = Array.from(input.querySelectorAll('option')).filter(o => o.selected);
            if (input.multiple)
                val = so.map(o => o.value);
            else if (so.length)
                val = so[0].value;
            else
                val = null;
        }
        else if (input instanceof HTMLInputElement && input.type == 'checkbox') {
            val = input.checked;
        }
        else if (input instanceof HTMLInputElement && input.type == 'radio') {
            val = input.checked ? input.value : out[name];
        }
        else if (input instanceof HTMLInputElement && input.type == 'file') {
            val = input.files ? (input.multiple ? Array.from(input.files) : input.files[0]) : void 0;
            input.value = '';
        }
        else if (input instanceof HTMLTextAreaElement || input instanceof HTMLInputElement) {
            val = input.value.trim();
        }
        const path = name.match(/[^\[\]]+|\[[^\[\]]*]/g).map(n => n.replace(/^\[(.*)]$/, '$1'));
        let cur = out;
        for (let i = 0; i < path.length; i++) {
            const cpart = path[i];
            const npart = path[i + 1];
            if (npart === void 0) {
                cur[cpart] = val;
                break;
            }
            if (npart === '') {
                if (cur[cpart] && !Array.isArray(cur[cpart])) {
                    throw new Error('Mixing array and non array keys (' + path.slice(0, i + 1).join('.') + ')');
                }
                if (cpart === '')
                    throw new Error('Nested arrays not supported');
                if (!cur[cpart])
                    cur[cpart] = [];
                cur[cpart].push(val);
                break;
            }
            if (!cur[cpart])
                cur[cpart] = {};
            cur = cur[cpart];
        }
    }
    return out;
}
export function queue(fun, until = (res) => res === queue.done) {
    let q = null;
    let done = false;
    let result;
    function run(qe) {
        if (done)
            return qe.resolve(result);
        try {
            qe.resolve(fun(...qe.args));
        }
        catch (e) {
            qe.reject(e);
        }
    }
    function next() {
        if (q && q.length) {
            const qe = q.shift();
            qe.promise.then(r => {
                if (!done && until(r)) {
                    done = true;
                    result = r;
                }
                next();
            }, e => {
                console.error(e);
                next();
            });
            run(qe);
        }
        else {
            q = null;
        }
    }
    return (...args) => {
        const start = !q;
        if (!q)
            q = [];
        const qe = { args };
        const p = qe.promise = new Promise((resolve, reject) => {
            qe.resolve = resolve;
            qe.reject = reject;
        });
        q.push(qe);
        if (start)
            next();
        return p;
    };
}
queue.done = {};
export function wrapSubmit(handler, btn) {
    let to;
    return queue(async (e) => {
        if (to)
            clearTimeout(to);
        try {
            btn.classList.remove('button--green');
            btn.classList.remove('button--red');
            btn.classList.add('button--disabled');
            btn.disabled = true;
            const res = await handler(e);
            btn.classList.add(res ? 'button--green' : 'button--red');
            to = setTimeout(() => btn.classList.remove('button--green'), 2000);
        }
        catch (e) {
            console.error(e);
            btn.classList.add('button--red');
            to = setTimeout(() => btn.classList.remove('button--red'), 2000);
        }
        finally {
            btn.disabled = false;
            btn.classList.remove('button--disabled');
        }
    });
}
export function promiseCallback(handler) {
    return new Promise((resolve, reject) => handler((err, result) => err ? reject(err) : resolve(result)));
}
export function nl2br(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/\n/g, '<br />');
}
export function resourceLink(c, rfn) {
    const pp = parsePath(rfn);
    return '/client/' + pp.name + '!' + (c.resourceHashes[rfn] || '000000').substr(0, 6) + pp.ext;
}
export function pick(obj, ...keys) {
    const out = {};
    for (let k of keys)
        if (obj.hasOwnProperty(k))
            out[k] = obj[k];
    return out;
}
export function ElementList({ children }) {
    return children;
}
const base36 = Array(36).fill(0).map((_, i) => String.fromCharCode(i >= 10 ? i - 10 + 'a'.charCodeAt(0) : i + '0'.charCodeAt(0)));
const getRandomNumbers = typeof window !== 'undefined' ? function generateIdImpl(length) {
    const arr = new Uint8Array(length);
    crypto.getRandomValues(arr);
    return Array.from(arr);
} : function generateIdImpl(length) {
    return Array.from(eval('require("crypto")').randomBytes(length));
};
export function generateId(length = 8) {
    return getRandomNumbers(length).map((n) => base36[n % 36]).join('');
}
