import { context } from '../client';
function extractBlobs(obj, acc = [], path = []) {
    if (!obj)
        return acc;
    if (Array.isArray(obj)) {
        obj.forEach((item, i) => {
            const np = path.concat(i);
            if (item instanceof Blob) {
                acc.push({ path: np, blob: item });
                obj[i] = void 0;
            }
            else {
                extractBlobs(item, acc, np);
            }
        });
    }
    else if (typeof obj == 'object') {
        for (let key in obj) {
            const np = path.concat(key);
            if (obj[key] instanceof Blob) {
                acc.push({ path: np, blob: obj[key] });
                obj[key] = void 0;
            }
            else {
                extractBlobs(obj[key], acc, np);
            }
        }
    }
    return acc;
}
export function post(fun, params) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/' + fun);
        xhr.setRequestHeader('X-CSRF-TOKEN', context.cookies.csrf);
        const blobs = extractBlobs(params);
        if (blobs && blobs.length) {
            const fd = new FormData();
            fd.append('json', JSON.stringify(params));
            for (let bd of blobs)
                fd.append(btoa(JSON.stringify(bd.path)), bd.blob);
            xhr.send(fd);
        }
        else {
            xhr.send(params && JSON.stringify(params));
        }
        xhr.onerror = e => resolve({ error: 'Connection error' });
        xhr.onload = () => {
            try {
                const res = JSON.parse(xhr.responseText);
                if (res.error)
                    resolve(res);
                else
                    resolve(res.result);
            }
            catch (e) {
                resolve({ error: 'Server error' });
            }
        };
    });
}
export const createDealer = (params) => post('createDealer', params);
export const createMerchant = (params) => post('createMerchant', params);
export const createTerminal = (params) => post('createTerminal', params);
export const deleteTerminal = (params) => post('deleteTerminal', params);
export const grantDealerAccess = (params) => post('grantDealerAccess', params);
export const grantMerchantAccess = (params) => post('grantMerchantAccess', params);
export const logOut = (params) => post('logOut', params);
export const requestRestoreEmail = (params) => post('requestRestoreEmail', params);
export const revokeDealerAccess = (params) => post('revokeDealerAccess', params);
export const revokeMerchantAccess = (params) => post('revokeMerchantAccess', params);
export const setDealerActive = (params) => post('setDealerActive', params);
export const setPasswordWithToken = (params) => post('setPasswordWithToken', params);
export const signIn = (params) => post('signIn', params);
export const signUp = (params) => post('signUp', params);
export const updateUser = (params) => post('updateUser', params);
