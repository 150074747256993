import { initLandingPage } from './landing';
import { initRestoreForm, initSetPasswordForm, initSignInForm, initSignUpForm } from '../blocks/auth';
import { initAppPage } from './app';
import { initMerchantPage } from './merchant';
import { initTerminalPage } from './terminal';
import { initDealersPage } from './dealers';
import { initDealerPage } from './dealer';
import { initSettingsPage } from './settings';
export const pageHandlerMap = {
    landing: initLandingPage,
    signIn: initSignInForm,
    signUp: initSignUpForm,
    restore: initRestoreForm,
    setPassword: initSetPasswordForm,
    app: initAppPage,
    merchant: initMerchantPage,
    terminal: initTerminalPage,
    dealers: initDealersPage,
    dealer: initDealerPage,
    settings: initSettingsPage,
};
export const pageHandlers = pageHandlerMap;
