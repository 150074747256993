import { initAppHeader } from '../blocks/header';
import { get, on } from 'dtk';
import { queue } from '../../common/util';
import { clearError, formError } from '../lib/util';
import { createMerchant } from '../lib/api';
export const initAppPage = (c, root, data) => {
    initAppHeader();
    initAddMerchantForm(get(root, 'form', '.app-main__add-merchant'));
};
function initAddMerchantForm(form) {
    let loginInput = get(form, 'input', '[name=login]');
    let dealerSelect = get(form, 'select', '[name=dealer]');
    let lastAuto = loginInput.value;
    let nameInput = get(form, 'input', '[name=label]');
    on(nameInput, 'change keyup', () => {
        if (lastAuto != loginInput.value && loginInput.value.trim())
            return;
        const val = nameInput.value.trim();
        const auto = val
            .replace(/\W\w/g, (s) => s.toUpperCase())
            .replace(/\W+/g, '')
            .substr(0, 16);
        loginInput.value = auto;
        lastAuto = auto;
    });
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const name = nameInput.value.trim();
        const login = loginInput.value.trim();
        const dealer = dealerSelect.value;
        if (!name || !login || !dealer)
            return formError(form, 'Please fill in all fields');
        const res = await createMerchant({ name, login, dealer });
        if (res.error || !res.merchant)
            formError(form, res.error || 'Unknown error');
        if (res.merchant) {
            location.href = `/app/${res.merchant.login}`;
            return queue.done;
        }
    }), { prevent: true });
}
