import { list, on } from 'dtk';
export function showBubble(x, y, body) {
    list('.bubble').forEach(b => b.parentElement.removeChild(b));
    const el = body;
    el.style.left = `${x}px`;
    el.style.top = `${y}px`;
    el.classList.remove('bubble--visible');
    document.body.appendChild(el);
    el.getBoundingClientRect();
    el.classList.add('bubble--visible');
}
export function showBubbleAt(el, body) {
    const ebr = el.getBoundingClientRect(); // FIXME rel to offset parent
    const pbr = document.body.getBoundingClientRect();
    return showBubble(ebr.left - pbr.left, ebr.bottom - pbr.top, body);
}
export function clearBubble() {
    list('.bubble').forEach(b => b.parentElement.removeChild(b));
}
on(document.body, 'click', e => {
    if (e.target.closest('.bubble'))
        return;
    clearBubble();
});
