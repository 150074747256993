import { create, get, on } from 'dtk';
import { queue } from '../../common/util';
import { clearError, formError } from '../lib/util';
import { createTerminal, grantMerchantAccess, revokeMerchantAccess } from '../lib/api';
import { showBubbleAt } from '../blocks/bubble';
import { initAppHeader } from '../blocks/header';
export const initMerchantPage = (c, root, data) => {
    initAppHeader();
    const merchant = data.merchant;
    initAddMerchantAccessForm(get(root, 'form', '.app-merchant__add-access'), merchant);
    const tt = get('.app-merchant__terminals-table');
    tt && on(tt, 'click', 'tr', e => {
        const login = e.target.closest('tr').dataset.id;
        if (login)
            location.href = `/app/${merchant.login}/${login}`;
    });
    const at = get(root, 'table', '.app-merchant__access-table');
    at && on(at, 'click', '.app-merchant__access-revoke', async (e) => {
        const access = e.target.closest('tr').dataset.id;
        const res = await revokeMerchantAccess({ access });
        if (res.error)
            showBubbleAt(e.target, create('div', 'bubble bubble--error', res.error));
        if (res.success)
            location.reload();
    });
};
function initAddMerchantAccessForm(form, merchant) {
    let emailInput = get(form, 'input', '[name=email]');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = emailInput.value.trim();
        if (!email)
            return formError(form, 'Please fill in the email address');
        const res = await grantMerchantAccess({
            merchant: merchant.id,
            email,
        });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success) {
            location.reload();
            return queue.done;
        }
    }), { prevent: true });
}
function initAddTerminalForm(form, merchant) {
    let numberInput = get(form, 'input', '[name=number]');
    let labelInput = get(form, 'input', '[name=label]');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const label = labelInput.value.trim();
        const number = numberInput.value.trim();
        if (!number)
            return formError(form, 'Please fill in ID');
        const res = await createTerminal({
            merchant: merchant.id,
            label,
            number,
        });
        if (res.error || !res.terminal)
            formError(form, res.error || 'Unknown error');
        if (res.terminal) {
            location.reload();
            return queue.done;
        }
    }), { prevent: true });
}
