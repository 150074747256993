import { get, on } from 'dtk';
import { requestRestoreEmail, setPasswordWithToken, signIn, signUp } from '../lib/api';
import { queue } from '../../common/util';
import { clearError, digestPassword, formError, formSuccess } from '../lib/util';
export const initSignInForm = (c, root) => {
    const form = get(root, 'form', '.auth');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = get(form, 'input', '[name=email]').value.trim();
        const pwtext = get(form, 'input', '[name=password]').value.trim();
        if (!email || !pwtext)
            return formError(form, 'Please fill in all fields');
        if (!email.includes('@'))
            return formError(form, 'Please ensure the email address is valid');
        const password = await digestPassword(pwtext);
        const res = await signIn({ email, password });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success) {
            location.href = '/app';
            return queue.done;
        }
    }), { prevent: true });
};
export const initSignUpForm = (c, root) => {
    const form = get(root, 'form', '.auth');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = get(form, 'input', '[name=email]').value.trim();
        const pwtext = get(form, 'input', '[name=password]').value.trim();
        const confirm = get(form, 'input', '[name=confirm]').value.trim();
        if (!email || !pwtext)
            return formError(form, 'Please fill in all fields');
        if (pwtext != confirm)
            return formError(form, 'Passwords do not match');
        const password = await digestPassword(pwtext);
        const res = await signUp({ email, password });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success) {
            location.href = '/app';
            return queue.done;
        }
    }), { prevent: true });
};
export const initRestoreForm = (c, root) => {
    const form = get(root, 'form', '.auth');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = get(form, 'input', '[name=email]').value.trim();
        if (!email)
            return formError(form, 'Please fill in the email');
        const res = await requestRestoreEmail({ email });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success)
            formSuccess(form, 'Please see your inbox for further instructions.');
    }), { prevent: true });
};
export const initSetPasswordForm = (c, root, { token }) => {
    const form = get(root, 'form', '.auth');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const pwtext = get(form, 'input', '[name=password]').value.trim();
        const confirm = get(form, 'input', '[name=confirm]').value.trim();
        if (!pwtext || !confirm)
            return formError(form, 'Please fill in all fields');
        if (pwtext != confirm)
            return formError(form, 'Passwords do not match');
        const password = await digestPassword(pwtext);
        const res = await setPasswordWithToken({ password, token });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success) {
            location.href = '/app';
            return queue.done;
        }
    }), { prevent: true });
};
