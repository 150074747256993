import { get, on } from 'dtk';
import { queue } from '../../common/util';
import { clearError, digestPassword, formError, formSuccess } from '../lib/util';
import { updateUser } from '../lib/api';
import { initAppHeader } from '../blocks/header';
export const initSettingsPage = (c, root) => {
    initAppHeader();
    const form = get(root, 'form', '.settings');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const email = get(form, 'input', '[name=email]').value.trim();
        const pwtext = get(form, 'input', '[name=password]').value.trim() || undefined;
        const confirm = get(form, 'input', '[name=confirm]').value.trim() || undefined;
        if (!email)
            return formError(form, 'Please fill in the email');
        if (pwtext && pwtext != confirm)
            return formError(form, 'Passwords do not match');
        const password = pwtext && await digestPassword(pwtext);
        const res = await updateUser({ email, password });
        if (res.error || !res.success)
            formError(form, res.error || 'Unknown error');
        if (res.success)
            formSuccess(form, 'Settings updated');
    }), { prevent: true });
};
