import { initAppHeader } from '../blocks/header';
import { get, on } from 'dtk';
import { queue } from '../../common/util';
import { clearError, formError } from '../lib/util';
import { createDealer } from '../lib/api';
export const initDealersPage = (c, root, data) => {
    initAppHeader();
    initAddDealerForm(get(root, 'form', '.app-main__add-dealer'));
};
function initAddDealerForm(form) {
    let nameInput = get(form, 'input', '[name=label]');
    on(form, 'submit', queue(async (e) => {
        clearError(form);
        const name = nameInput.value.trim();
        if (!name)
            return formError(form, 'Please fill in all fields');
        const res = await createDealer({ name });
        if (res.error || !res.dealer)
            formError(form, res.error || 'Unknown error');
        if (res.dealer) {
            location.href = `/dealer/${res.dealer.id}`;
            return queue.done;
        }
    }), { prevent: true });
}
