import { en } from './lang/en';
export const defaultLanguage = 'en';
export const languages = ['en'];
export const languageNames = { en: 'English' };
export const translations = { en };
function langFromThis(that) {
    return typeof that == 'string' ? that : (that && that.lang) ? that.lang : defaultLanguage;
}
export function getTranslation(id, ...args) {
    let lang = langFromThis(this);
    const val = translations[lang][id] || translations[defaultLanguage][id] || id;
    if (typeof val != 'function')
        return val;
    return val(...args);
}
export function str(arg) {
    return typeof arg == 'string' ? arg : arg[langFromThis(this)] || arg.en;
}
