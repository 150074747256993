import * as cookie from 'cookie';
import * as url from 'url';
import { pageHandlers } from './pages/pages';
import { getTranslation, defaultLanguage, str } from '../common/i18n';
import { setCookie } from '../common/util';
import '../scss/client.scss';
const data = JSON.parse(document.getElementById('data').textContent);
const cookies = cookie.parse(document.cookie);
cookies.csrf = data.csrf;
const parsed = url.parse(location.pathname, true);
const parts = parsed.pathname.split('/').filter(Boolean);
const lang = (parts.shift() || cookies.lang || defaultLanguage);
setCookie('lang', lang);
export const context = {
    cookies,
    url: location.pathname + location.search,
    lang,
    t: getTranslation,
    str,
    resourceHashes: {},
    config: data.config,
};
context.t = getTranslation.bind(context);
context.str = str.bind(context);
if (data.pageHandler) {
    pageHandlers[data.pageHandler](context, document.body, data.pageData);
}
