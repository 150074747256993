import { get } from 'dtk';
export function formError(form, text) {
    const err = get(form, '.form__error');
    err.textContent = text;
    err.classList.add('form__error--visible');
}
export function formSuccess(form, text) {
    const err = get(form, '.form__success');
    err.textContent = text;
    err.classList.add('form__success--visible');
}
export function clearError(form) {
    const err = get(form, '.form__error');
    err && err.classList.remove('form__error--visible');
    const suc = get(form, '.form__success');
    suc && suc.classList.remove('form__success--visible');
}
const salt = 'chloride';
export async function digestPassword(text) {
    let buf = (new TextEncoder()).encode(text + salt).buffer;
    for (let i = 0; i < 100; i++)
        buf = await window.crypto.subtle.digest('SHA-256', buf);
    return Array.from(new Uint8Array(buf)).map(b => b.toString(16).padStart(2, '0')).join('');
}
