import { create, get, on } from 'dtk';
import { initViewer } from '../blocks/viewer';
import { clearBubble, showBubbleAt } from '../blocks/bubble';
import { deleteTerminal } from '../lib/api';
import { initAppHeader } from '../blocks/header';
export const initTerminalPage = (c, root, data) => {
    initAppHeader();
    const vctr = get('.app-terminal__viewer');
    initViewer(vctr, {
        cookie: data.cookie,
        terminal: data.terminal.id
    });
    on(get('.app-terminal__viewer-maximize'), 'click', e => {
        vctr.classList.add('app-terminal__viewer--maximized');
    });
    on(get('.app-terminal__viewer-close'), 'click', e => {
        vctr.classList.remove('app-terminal__viewer--maximized');
    });
    on(get('.app-terminal__delete'), 'click', e => {
        const yes = create('button', {}, 'Delete');
        const no = create('button', {}, 'Cancel');
        showBubbleAt(e.target, create('div', 'bubble bubble--error', create('p', {}, 'Are you sure you want to delete this terminal?'), create('div', 'bubble__buttons', yes, no)));
        on(no, 'click', () => clearBubble());
        on(yes, 'click', async () => {
            const res = await deleteTerminal({ terminal: data.terminal.id });
            if (res.error)
                showBubbleAt(e.target, create('div', 'bubble bubble--error', res.error));
            if (res.success)
                location.href = '/app/' + data.merchant.login;
        });
    }, { stop: true });
};
